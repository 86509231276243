<template>
    <div class="col s12 orange white-text left-aling panel-title">
        <h5>{{ text }}</h5>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .panel-title{
        margin-top: 30px; 
        margin-bottom: 30px;;
    }
</style>