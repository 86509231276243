<template>
  <div class="container">
    <FloatingButton icon="fas fa-home" @click="goBack"/>
    <LoadingFullPage v-if="isLoading"/>
    <div class="row" v-else>
        <Title text="Monederos" />
        <div class="col s12 left-align white balances-box">
            <h5>Total Depositado en los Monederos:</h5>
            <h4>{{ total }} <i class="fab fa-bitcoin orange-text"></i></h4>
            <h4>{{ totalUSD }} <span class="light-green-text">USD</span></h4>
            <h5>Monederos con Saldo: <span class="orange-text">{{ activos }}</span></h5>
        </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Admin/Title'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Title, FloatingButton, LoadingFullPage },
    setup(){
        
        const { router, isLoading } = useBoilerplate(true);
        const total = ref(0);
        const totalUSD = ref(0);
        const activos = ref(0);
        const goBack = () => router.push({name: "AdminPanel"});
        
        onMounted(async () => {
            try {
                const url = '/api/total-balances';
                const res = await fetch(url, {cache: "no-cache"});
                const data = await res.json();
                total.value = data.btc;
                totalUSD.value = data.usd;
                activos.value = data.activos;
                isLoading.value = false;
            } catch (err) {
                console.log(err);
                isLoading.value = false;
            }
        })
        return { goBack, isLoading, total, totalUSD, activos }
    }
}
</script>

<style scoped>
    .blue-text{ cursor: pointer; }
    .balances-box{ 
        padding-top: 30px !important;
        padding-bottom: 150px !important;
        margin-bottom: 200px !important;
    }
</style>